import React from 'react'
import { MdTrendingFlat } from "react-icons/md";
import { useStaticQuery, graphql } from "gatsby"
import {List, ListItem, ListLink} from './post-list.style'

const RecentPostList = ({listStyle}) => {
        const blogData = useStaticQuery(graphql `
        query pointsMilesData{
             wpcontent {
                posts(first: 7, where: {categoryName: "Points & Miles"}) {
                  edges {
                    node {
                      title
                      slug
                    }
                  }
                }
              }
        }
    `)    

    const latestBlogs = blogData.wpcontent.posts.edges;
    return (
        <List>
            {latestBlogs && latestBlogs.map((latestBlog, i) => (
                <ListItem key={`${latestBlog.node.slug}-${i}`}>
                    <ListLink path={`/${latestBlog.node.slug}`}>
                        <MdTrendingFlat className="icon icon-1"/>
                        <MdTrendingFlat className="icon icon-2"/>
                        <span>{latestBlog.node.title}</span>
                    </ListLink>
                </ListItem>
            ))}
        </List>
    )
}

export default RecentPostList;