import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from "../../components/seo"
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-one'
import Footer from '../../containers/layout/footer/footer-one'
import PageHeader from '../../components/pageheader'
import { Container, Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import Sidebar from '../../containers/blog/sidebar'
import CTA from '../../containers/global/cta-area/section-one'
import Blog from '../../components/blog/layout-two'
import { BlogListWrapper, BlogBoxWrapper, BlogBox } from './category-template.style'

const CategoryTemplate = ({ data, pageContext, location, ...restProps }) => {
    const { headingStyle } = restProps;
    const { category, name } = pageContext;
    const blogs = data.posts.posts.edges;
    return (
        <Layout location={location}>
            <SEO title={`Category: ${name}`} />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title={name}
            />
            <main className="site-wrapper-reveal">
                <BlogListWrapper>
                    <Container>
                        <Row>
                            <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
                                <Sidebar />
                            </Col>
                            <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
                                <BlogBoxWrapper>
                                    {blogs.map(blog => (
                                        <BlogBox key={blog.node.slug}>
                                            <Blog content={blog.node} />
                                        </BlogBox>
                                    ))}
                                </BlogBoxWrapper>
                            </Col>
                        </Row>
                    </Container>
                </BlogListWrapper>
                <CTA />
            </main>
            <Footer />
        </Layout>
    )
}

export const query = graphql`
    query BlogByCategoryQuery($name: String!){
        posts : wpcontent {
        posts(first: 100, where: {categoryName: $name}) {
          edges {
            node {
              id      
              postId
              excerpt
              featuredImage {
                node {
                  id
                  mediaDetails {
                    file
                  }
                  sourceUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1170, maxHeight: 570, quality: 100) {
                        presentationWidth
                        presentationHeight
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              title(format: RENDERED)
              author {
                node {
                  id
                  name
                  avatar {
                    url
                  }
                }
              }
              slug
              date
              tags(first: 10) {
                edges {
                  node {
                    id
                  }
                }
              }
              categories(first: 10) {
                edges {
                  node {
                    id
                    name
                    slug
                    categoryId
                  }
                }
              }
            }
          }  
        }
      }
    }
`;


CategoryTemplate.propTypes = {
    headingStyle: PropTypes.object
}

CategoryTemplate.defaultProps = {
    headingStyle: {
        as: 'h3',
        mb: '70px',
        textalign: 'center',
        child: {
            color: 'primary'
        },
        responsive: {
            medium: {
                mb: '50px'
            }
        }
    }
}

export default CategoryTemplate;